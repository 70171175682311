import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";
import { ROUTES } from "../core/constants/routesName";
import { useAuth } from "../core/providers/authProvider";
import { createLazy } from "./lazyLoading";

const ApplicationRoutes = () => {
  const { accessToken } = useAuth();

  const Login = createLazy(() => import("../components/pages/login"));
  const PrivateRoutes = createLazy(() => import("./PrivateRoutes"));

  return (
    <RouterRoutes>
      {accessToken ? (
        <Route path={"/*"} element={<PrivateRoutes />} />
      ) : (
        <Route>
          <Route path={ROUTES.LOGIN.$path()} element={<Login />} />
          <Route path="*" element={<Navigate to={ROUTES.LOGIN.$path()} />} />
        </Route>
      )}
    </RouterRoutes>
  );
};

export { ApplicationRoutes };
