import { useQueryClient } from "@tanstack/react-query";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { persistKeys } from "../../constants/persistKeys";
import { ROUTES } from "../../constants/routesName";

interface Props {
  accessToken: string;
  setAccessToken: Dispatch<SetStateAction<string>>;
}

const initialValue: Props = {
  accessToken: "",
  setAccessToken: () => undefined,
};

const AuthContext = createContext(initialValue);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState<string>(
    localStorage.getItem(persistKeys.ACCESS_TOKEN) || "",
  );

  const queryClient = useQueryClient();
  queryClient.setDefaultOptions({
    queries: {
      retry: false,
      refetchOnWindowFocus: false,

      onError(err) {
        // @ts-ignore
        if (err && err.status && err.status === 401) {
          localStorage.removeItem(persistKeys.ACCESS_TOKEN);
          setAccessToken("");
          navigate(ROUTES.LOGIN.$path());
        }
      },
    },
    mutations: {
      retry: false,

      onError(err) {
        // @ts-ignore
        if (err && err.status && err.status === 401) {
          localStorage.removeItem(persistKeys.ACCESS_TOKEN);
          setAccessToken("");
          navigate(ROUTES.LOGIN.$path());
        }
      },
    },
  });

  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
