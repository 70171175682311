import { route, string } from "react-router-typesafe-routes";

export const ROUTES = {
  BANK_ACCOUNTS: route({ path: "bank_accounts" }),
  COIN_DATA_MANAGEMENT: route({ path: "coins_management" }),
  COLD_BALANCES: route({ path: "cold_balances" }),
  DASHBOARD: route({ path: "dashboard" }),
  DEPOSITS: route({ path: "deposits" }),
  FATA_MANAGEMENT: route({ path: "fata_management" }),
  LOGIN: route({ path: "login" }),
  KYC: route({ path: "kyc" }),
  MANAGEMENT: route({ path: "management" }),
  ORDERS_MANAGEMENT: route({ path: "orders_management" }),
  OPERATOR_PROFILE: route({ path: "operator_profile" }),
  OPERATORS_MANAGEMENT: route({ path: "operators_management" }),
  PAYROLL: route({ path: "payroll" }),
  SERVICE_HEALTH: route({ path: "service_health" }),
  TICKET: route({ path: "ticket" }),
  TICKET_DETAIL: route({
    path: "ticket_detail",
    searchParams: {
      ticketId: string(),
    },
  }),
  TOTAL_BALANCES: route({ path: "total_balances" }),
  USER_ACTIONS: route({ path: "user_actions" }),
  USER_PROFILE: route({
    path: "user_profile",
    searchParams: {
      userUuid: string(),
    },
  }),
  USERS: route({ path: "users" }),
  WITHDRAWS: route({ path: "withdraws" }),
};
